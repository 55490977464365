/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {
  Layout,
  WorkerCard,
  SliderCarousel,
  SEO,
  ContentSection,
  TestimonialsCarousel,
  Contact,
  HeroImg,
} from '../components'
import {ListItem} from '../components/Listing'
import {Container, PostsWrapper} from '../elements'
import GalleryImgs from '../utils/galleryImgs'
import videoMp4 from '../assets/dalia-pugatsch-video.mp4'
import videoOgv from '../assets/dalia-pugatsch-video.ogv'
import videoWebm from '../assets/dalia-pugatsch-video.webm'
import Navigation from '../components/navigation'

const SchuleSite = ({
  data: {prismicSchule, Testimonials, prismicForm, allEvents, i18nPathname},
  location,
}) => {
  const {lang} = prismicForm
  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO data={prismicSchule.data} pathname={location.pathname} />
      <div
        sx={{
          display: ['inline-block', '', 'none'],
        }}
      >
        <HeroImg
          title={prismicSchule.data.header_title}
          subtitle={prismicSchule.data.header_subtitle}
          bg={prismicSchule.data.header_bg.localFile.childImageSharp.fluid}
        />
      </div>

      <div
        sx={{
          overflow: 'hidden',
          display: ['none', '', 'inline-block'],
          position: 'relative',
          minHeight: '100vh',
          minWidth: '100vw',
          mb: [(t) => t.layout.separation.mobile, '', '10rem'],
        }}
      >
        <div
          sx={{
            zIndex: 2,
            position: `relative`,
            minHeight: `90vh`,
            display: `flex`,
            flexDirection: 'column',
            alignItems: `left`,
            maxWidth: (t) => t.layout.big,
            p: '0 1.5rem',
            margin: `0 auto`,
            mb: [
              (t) => t.layout.separation.mobile,
              '',
              (t) => t.layout.separation.desktop,
            ],
            justifyContent: 'center',
          }}
        >
          <Styled.h1
            sx={{
              textAlign: 'center',
              textShadow: [(t) => `${t.shadows.text.big}`],
              lineHeight: `1.1`,
              fontSize: [4, 6, 7],
              mt: 2,
              mb: 3,
              letterSpacing: `wide`,
              color: (t) => t.colors.gray[1],
            }}
          >
            {prismicSchule.data.header_title}
          </Styled.h1>
          <Styled.h2
            sx={{
              textAlign: 'center',
              textShadow: [(t) => `${t.shadows.text.big}`],
              fontSize: [4, 5, 6],
              lineHeight: `1.1`,
              fontWeight: `normal`,
              color: (t) => t.colors.gray[2],
              mt: 0,
            }}
          >
            {prismicSchule.data.header_subtitle}
          </Styled.h2>
        </div>
        <div
          sx={{
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1,
              background:
                'linear-gradient(45deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))',
              // background: 'linear-gradient(45deg, rgba(237, 137, 54, 0.75), rgba(0, 0, 0, 0.6))',
            },
          }}
        >
          <video
            sx={{
              zIndex: -1,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              minWidth: '100%',
              minHeight: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            playsinline
            autoPlay
            muted
            loop
          >
            <source src={videoMp4} type="video/mp4" />
            <source src={videoWebm} type="video/webm" />
            <source src={videoOgv} type="video/ogg" />
          </video>
        </div>
      </div>

      <Container type="big" id="courses-card">
        <Styled.h1
          sx={{
            textAlign: 'center',
            pb: ['3rem', '', '5rem'],
            fontSize: ['1.5rem'],
          }}
        >
          {prismicSchule.data.section_1_content}
        </Styled.h1>
        <WorkerCard
          portrait={
            prismicSchule.data.teacher_image.localFile.childImageSharp.fluid
          }
          worker={prismicSchule.data.teacher_name}
          jobTitle={prismicSchule.data.job_title}
          workerDesc={prismicSchule.data.teacher_description}
          btn1={prismicSchule.data.button_1}
          btn2={prismicSchule.data.button_2}
          cardTitle={prismicSchule.data.card_title}
          cardDescription={prismicSchule.data.card_description}
          requirements={prismicSchule.data.requirements}
          requirementDesc={prismicSchule.data.requirement_description}
          preis={prismicSchule.data.preis}
          priceNotes={prismicSchule.data.price_notes}
        />
      </Container>

      <Container type="big">
        <Styled.h1
          sx={{
            textAlign: 'center',
          }}
        >
          {prismicSchule.data.title}
        </Styled.h1>
        <div
          sx={{
            pr: ['0', '', '2rem'],
            pl: ['0', '', '2rem'],
          }}
        >
          <SliderCarousel
            gallery={GalleryImgs(prismicSchule.data.slideshow)}
            srcImgs={prismicSchule.data.slideshow}
            slidesToShow={3}
          />
        </div>
      </Container>

      <Container>
        <div
          sx={{
            'div:not(:last-child)': {
              pb: ['6rem', '', '12rem'],
            },
            'div > div:not(:last-child)': {
              pb: 0,
            },
          }}
        >
          {prismicSchule.data.body.map((item, i) => (
            <ContentSection
              title={item.primary.titel}
              content={item.primary.inhalt}
              lgImage={item.primary}
              isReversed={i % 2}
            />
          ))}
        </div>
      </Container>

      <div
        sx={{
          backgroundColor: 'primary',
          textAlign: 'center',
        }}
      >
        <Container>
          <div
            sx={{
              pt: '4rem',
              pb: '4rem',
              h1: {
                fontSize: ['1.8rem', '', '3rem'],
                color: (t) => t.colors.gray[1],
              },
              p: {
                color: '#fde3ce',
                fontSize: ['1.4rem', '', '2rem'],
                mt: 0,
                mb: 0,
              },
            }}
          >
            <Styled.h1>{prismicSchule.data.voucher_title}</Styled.h1>
            <Styled.p>{prismicSchule.data.voucher_content}</Styled.p>
          </div>
        </Container>
      </div>
      <div
        sx={{
          mb: ['6rem', '', '16rem'],
          p: ['0 1.5rem', '', '0'],
          maxWidth: '1400px',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        <Styled.h1
          sx={{
            textAlign: 'center',
            mb: '3rem',
          }}
        >
          {prismicSchule.data.titel_testimonials}
        </Styled.h1>
        <TestimonialsCarousel testimonials={Testimonials.edges} />
      </div>

      {/* If there's events data */}
      {allEvents.nodes.length > 0 && (
        <Container>
          <Styled.h1
            sx={{
              mb: [0, '', '1rem'],
            }}
          >
            Kommende Veranstaltungen
          </Styled.h1>
          <PostsWrapper>
            {allEvents.nodes.map((item) => (
              <ListItem
                img={item.data.image.localFile.childImageSharp.fluid}
                capTitle={item.data.cb_title}
                date={item.data.event_date}
                path={`/veranstaltungen/${item.uid}`}
                isFuture={item.isFuture}
              />
            ))}
          </PostsWrapper>
        </Container>
      )}

      <Contact data={prismicForm} lang={lang} />
    </Layout>
  )
}

export const query = graphql`
  query Schule($lang: String) {
    i18nPathname: allPrismicSchule {
      nodes {
        uid
        lang
      }
    }

    prismicSchule(lang: {eq: $lang}) {
      uid
      data {
        header_title
        header_subtitle
        header_bg {
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section_1_content
        teacher_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        teacher_name
        job_title
        teacher_description
        button_1
        button_2
        card_title
        card_description {
          html
          text
        }
        requirements
        requirement_description
        preis
        price_notes

        title

        titel_testimonials
        slideshow {
          image {
            url
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                original {
                  height
                  src
                  width
                }
              }
            }
          }
        }

        body {
          ... on PrismicSchuleBodyBildUndText {
            slice_type
            id
            primary {
              titel
              inhalt
              it_image {
                url
                localFile {
                  childImageSharp {
                    original {
                      height
                      src
                      width
                    }
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        voucher_title
        voucher_content

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }

    Testimonials: allPrismicTestimonial(filter: {lang: {eq: $lang}}) {
      edges {
        node {
          data {
            name
            msg
            job_title {
              html
            }
            portrait_photo {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicForm(lang: {eq: $lang}) {
      lang
      ...FormInformation
    }

    allEvents: allPrismicEvents(
      sort: {fields: data___event_date, order: ASC}
      filter: {isFuture: {eq: true}}
      limit: 2
    ) {
      nodes {
        ...EventPost
      }
    }
  }
`

export default SchuleSite
